// export const onInitialClientRender = () => {
// }

export const onClientEntry = async () => {
    if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
    }
}

export const registerServiceWorker = () => true

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
        window.location.reload()
    }
}
